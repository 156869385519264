const config = {
  appName: "Spookybiz",
  phoneNumber: {
    Default: "ke",
    Value: "+254",
  },
  server: {
    // Force debug on at all times
    ForceDebug: false,
    // Sets the timer for how often we check if the token needs to be refreshed, set 0 for every 10s.
    tokenRefreshTimer: 20, // minutes
    // How often the user data is refreshed eg notifications
    dataRefreshTimer: 5, // minutes
    dev: {
      url: "http://localhost:5000/api",
    },
    prod: {
      url: "https://spookybiz-backend.herokuapp.com/api",
    },
    // This is used to show an errors if a password does not match the password complexity requirments in real time
    PasswordComplexity: true,
  },
  theme: {
    // You can choose between "dark" or "light"
    DefaultTheme: "dark",
    // You can choose the application colors here
    MainColor: "", // use hex eg #000000
    SecondaryColor: "#f5a623",
    ColorContrast: {
      // Lets you make your main and secondary color lighter or darker or the same
      // This calculation is based of if the light theme is default
      MainContrast: true,
      SecondaryContrast: false,
      // To make darker us a negative number and use a positive number to make it lighter
      MainContrastAmount: 20,
      SecondaryContrastAmount: 40,
    },
    Dark: {
      background: "#242729",
      paper: "#323638",
    },
    Light: {
      background: "",
      paper: "",
    },
    LinkColor: {
      // Changes the way the colours for the app draw work
      custom: false,
      // If primary is false will use secondary colour (If custom is true)
      primary: false,
    },
    Notification: {
      ThemeNotifications: true,
      MaterialNotifications: false,
      // Check out react-toastify for how to configure below options, more options can be found in CustomThemeProvider
      Position: "top-center",
      CloseTimer: 5000,
    },
  },
};

export default config;
