import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

// styling
import CssBaseline from "@mui/material/CssBaseline";
import CustomThemeProvider from "./Components/common/CustomThemeProvider";
// Routing
import { BrowserRouter as Router } from "react-router-dom";
//Session Mananger
import SessionManagerProvider from "./Auth/SesssionManager";
import { who, refresh } from "./CustomAxios/Protected";

import App from "./App";
import config from "./Config";
import { axiosAuth } from "./CustomAxios/Protected";

document.title = config.appName;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <SessionManagerProvider
        userLoader={who}
        refreshToken={refresh}
        AuthenticatedAxiosObject={axiosAuth}
        refreshTimer={config.server.tokenRefreshTimer}
        dataRefresh={config.server.dataRefreshTimer}
      >
        <CustomThemeProvider>
          <CssBaseline />
          <App />
        </CustomThemeProvider>
      </SessionManagerProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
