import React from "react";
import { Typography, Link, Box } from "@mui/material";

import Main from "./Pages/Main/Main";

export function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ my: 1 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://github.com/Skulldorom">
        Skulldorom
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  return (
    <>
      <LandingPage />
    </>
  );
}

function LandingPage() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        flexDirection="column"
      >
        <Main />
        <Box pt={4}>
          <Copyright />
        </Box>
      </Box>
    </>
  );
}

export default App;
