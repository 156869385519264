import React from "react";

// Notifications
import { toast } from "react-toastify";

export const SessionManager = React.createContext({
  isLoggedIn: null,
  header: null,
  isAdmin: null,
  userInfo: null,
  refreshData: null,
  setHeader: null,
  setLoggedin: null,
  setRefreshData: null,
  hasRole: null,
});

const SessionManagerProvider = ({
  refreshTimer,
  dataRefresh,
  userLoader,
  refreshToken,
  AuthenticatedAxiosObject,
  children,
}) => {
  // State to hold the selected header name
  const [current, setCurrent] = React.useState("");

  AuthenticatedAxiosObject.defaults.withCredentials = true;
  AuthenticatedAxiosObject.defaults.headers.common["Authorization"] = current;

  const fromPrevious = React.useCallback(
    (auth, remember) => {
      setCurrent(auth);
      setTimeout(() => {
        refreshToken()
          .then((data) => {
            if (data.refreshed) {
              const token = `Bearer ${data.access_token}`;
              setCurrent(token);
              AuthenticatedAxiosObject.defaults.headers.common[
                "Authorization"
              ] = token;
              if (remember) localStorage.setItem("Authorization", token);
              sessionStorage.setItem("Authorization", token);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("Missing/Invalid Token");
            localStorage.removeItem("Authorization");
            sessionStorage.removeItem("Authorization");
          });
      }, 100);
    },
    [AuthenticatedAxiosObject, refreshToken]
  );

  React.useEffect(() => {
    const local_auth = localStorage.getItem("Authorization");
    const sess_auth = sessionStorage.getItem("Authorization");

    if (local_auth) fromPrevious(local_auth, true);
    else if (sess_auth) fromPrevious(sess_auth, false);
    return () => clearInterval(fromPrevious);
  }, [current, fromPrevious]);

  // Wrap setHeader to store new header names in localStorage
  const setHeader = (header) => {
    setCurrent(header);
  };

  const [currentLoggin, setCurrentLoggedIn] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});

  React.useEffect(() => {
    setTimeout(() => {
      userLoader()
        .then((res) => {
          const data = res.data;
          setCurrentLoggedIn(data.logged_in);
          setIsAdmin(data.is_admin);
          setUserInfo(data.Info);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 100);
  }, [current, currentLoggin, userLoader]);

  const setLoggedin = (status) => {
    setCurrentLoggedIn(status);
  };

  React.useEffect(() => {
    const tokenRefreshTimer = () => {
      const mins = refreshTimer || 60;
      return mins * 60 * 1000 || 10000;
    };

    let interval = null;
    if (currentLoggin) {
      const remember = localStorage.getItem("Authorization") ? true : false;
      interval = setInterval(() => {
        fromPrevious(sessionStorage.getItem("Authorization"), remember);
      }, tokenRefreshTimer());
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [current, fromPrevious, currentLoggin, refreshTimer]);

  React.useEffect(() => {
    const customInterceptor = () => {
      AuthenticatedAxiosObject.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error?.response?.status === 455) {
            try {
              const status = error.response.data.logged_in || false;
              if (currentLoggin && !status) {
                setCurrentLoggedIn(false);
                AuthenticatedAxiosObject.defaults.headers.common[
                  "Authorization"
                ] = ``;
                toast.info(
                  "Your session in no longer valid, please login again.",
                  { toastId: "Forced_log_out" }
                );
              }
            } catch (err) {
              throw err;
            }
          }
          if (!error?.response?.status) {
            toast.error(
              "The server is not responding, please reload or try again later.",
              { toastId: "ERR_CONNECTION_REFUSED" }
            );
          }
          throw error;
        }
      );
    };
    return customInterceptor;
  });

  // We will use the below to refresh our data about the user when ever we flag refreshData as true
  const [refreshData, setRefreshFlag] = React.useState(false);

  React.useEffect(() => {
    const refreshDelay = () => {
      const mins = dataRefresh || 60;
      return mins * 60 * 1000 || 10000;
    };

    const timer = setTimeout(() => setRefreshFlag(true), refreshDelay());
    return () => clearTimeout(timer);
  }, [dataRefresh]);

  React.useEffect(() => {
    if (refreshData) {
      userLoader().then((res) => {
        const data = res.data;
        setUserInfo(data.Info);
        setRefreshData(false);
      });
    }
  }, [refreshData, userLoader]);

  const setRefreshData = (status) => {
    setRefreshFlag(status);
  };

  // Check if user has specific role

  const hasRole = (roles) => {
    const found = roles.some((r) => userInfo?.roles?.indexOf(r) >= 0);
    return found;
  };

  const contextValue = {
    isLoggedIn: currentLoggin,
    header: current,
    isAdmin: isAdmin,
    userInfo: userInfo,
    refreshData: refreshData,
    setHeader: setHeader,
    setLoggedin: setLoggedin,
    setRefreshData: setRefreshData,
    hasRole: hasRole,
  };

  return (
    <SessionManager.Provider value={contextValue}>
      {children}
    </SessionManager.Provider>
  );
};

export default SessionManagerProvider;
