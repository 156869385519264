import React from "react";
import logo from "../../logo.svg";

import { Typography, Grid, Stack, Box } from "@mui/material";

export default function Main(props) {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="body1" color="primary" align="center">
            <img
              src={logo}
              style={{ height: "25rem", maxHeight: "50vh" }}
              alt="logo"
            />
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography
              color="primary"
              variant="h1"
              component="h1"
              sx={{ fontSize: "5rem" }}
            >
              Spooky
            </Typography>
            <Typography
              color="secondary"
              variant="h1"
              component="h1"
              sx={{ fontSize: "5rem" }}
            >
              biz
            </Typography>
          </Box>

          <Typography variant="body1" align="center" sx={{ mb: 3, px: 2 }}>
            The only budgeting app you need. Simple, easy to use, and free.
          </Typography>

          <Typography variant="body1" align="center" sx={{ mb: 3, px: 2 }}>
            Budgeting can be scary, but it doesn't have to be. Spookybiz is here
            to help you manage your money and keep track of your spending.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="center">
            <Typography variant="h3" color="primary">
              Coming
            </Typography>
            <Typography variant="h3" color="secondary">
              Soon
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
